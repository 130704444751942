












































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { appendSitePrefix } from '@/utils/routeUtils'
import { vxm } from '@/store'
import Customer from '@/models/customer/Customer.ts'
import Car from '@/models/car/Car.ts'
import { Debounce } from 'lodash-decorators'
import EmailDialog from '@/components/notification/EmailDialog.vue'
import SmsDialog from '@/components/notification/SmsDialog.vue'

@Component({
  methods: { appendSitePrefix },
  components: { SmsDialog, EmailDialog },
})
export default class CustomerData extends Vue {
  @Prop({ type: Object, required: true })
  private customer: Customer

  @Prop({ type: Object, required: true })
  private payer: Customer

  @Prop({ type: Object, required: true })
  private car: Car

  private smsDialog = false
  private emailDialog = false
  private emailDialogRecipient = null
  private smsDialogRecipient = null

  private changeCustomerSection = false

  private customerSearchLoading = false
  private customerSearch = ''

  private filteredCustomers = []

  private get customerId() {
    return this.customer.id
  }

  private get payerId() {
    return this.payer.id
  }

  private get carLicenseplate() {
    return this.car.id
  }

  private newCarLink() {
    let url = '/N0/car/new'
    if (this.car.licenseplate !== null && this.car.licenseplate !== '') {
      url += '?reg_no=' + this.car.licenseplate
    }

    url = appendSitePrefix(url)
    window.open(url, '_blank', 'noreferrer')
  }

  private newCustomerLink() {
    let url = '/N0/new_customer_and_car/main/null'
    url = appendSitePrefix(url)
    window.open(url, '_blank', 'noreferrer')
  }

  @Watch('customerSearch')
  @Debounce(300)
  private onCustomerSearchChanged() {
    if (this.customerSearch !== null) {
      this.customerSearchLoading = true
      this.$axios
        .get('v4/site/customers/search?search=' + this.customerSearch)
        .then((response) => {
          this.filteredCustomers = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not search customers')
        })
        .finally(() => {
          this.customerSearchLoading = false
        })
    }
  }

  private changeCustomerSectionToggle() {
    this.changeCustomerSection = !this.changeCustomerSection
  }

  private openEmailDialog() {
    this.emailDialog = true
    this.emailDialogRecipient = this.customer.email
  }

  private openSmsDialog() {
    this.smsDialog = true
    this.smsDialogRecipient = this.customer.phone
  }
}
