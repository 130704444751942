



























































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Order from '@/models/order/Order'
import { appendSitePrefix } from '@/utils/routeUtils'
import Customer from '@/models/customer/Customer'
import Driver from '@/models/car/Driver'
import Car from '@/models/car/Car'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'

@Component({
  components: {},
  methods: { appendSitePrefix },
})
export default class CustomerTabData extends Vue {
  @Prop({ type: Object })
  private order: Order

  @Prop({ type: Object })
  private customer: Customer

  @Prop({ type: Object })
  private payer: Customer

  @Prop({ type: Object })
  private driver: Driver

  @Prop({ type: Object })
  private car: Car

  private customerSearchLoading = false
  private customerSearch = ''
  private filteredCustomers = []

  private payerSearchLoading = false
  private payerSearch = ''
  private filteredPayers = []

  private changeCustomerDialog = false
  private changePayerDialog = false

  private editDriverDialog = false

  private removeCarDialog = false
  private changeCarDialog = false

  private changeDeliveryModeDialog = false
  private refreshDeliveryAddressDialog = false
  private editDeliveryAddressDialog = false

  private appendSitePrefix = appendSitePrefix

  countryMapper: { [key: string]: string } = {
    SE: 'Sweden',
    NO: 'Norway',
  }

  private created() {}

  private getCountry(entity: { country?: string; countryCode?: string }): string {
    const countryKey = entity.country || entity.countryCode || ''
    return this.countryMapper[countryKey] || countryKey
  }

  private editEntity(id) {
    let url = `/${id}/customer/edit/null`
    url = appendSitePrefix(url)
    window.open(url, '_blank', 'noreferrer')
  }

  private editCar(id) {
    let url = `/${id}/car/edit?from=customer`
    url = appendSitePrefix(url)
    window.open(url, '_blank', 'noreferrer')
  }

  @Watch('customerSearch')
  @Debounce(300)
  private onCustomerSearchChanged() {
    if (this.customerSearch !== null) {
      this.customerSearchLoading = true
      this.$axios
        .get('v4/site/customers/search?search=' + this.customerSearch)
        .then((response) => {
          this.filteredCustomers = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not search customers')
        })
        .finally(() => {
          this.customerSearchLoading = false
        })
    }
  }

  @Watch('payerSearch')
  @Debounce(300)
  private onPayerSearchChanged() {
    if (this.payerSearch !== null) {
      this.payerSearchLoading = true
      this.$axios
        .get('v4/site/customers/search?search=' + this.payerSearch)
        .then((response) => {
          this.filteredPayers = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not search customers')
        })
        .finally(() => {
          this.payerSearchLoading = false
        })
    }
  }

  private newCustomerLink() {
    let url = '/N0/new_customer_and_car/main/null'
    url = appendSitePrefix(url)
    window.open(url, '_blank', 'noreferrer')
  }
}
