













































import { Component, Prop, Vue } from 'vue-property-decorator'
import Order from '@/models/order/Order'
import ListView from '@/components/list/ListView.vue'
import json from '../mockup/mockup-history-lines.json'

@Component({
  components: { ListView },
})
export default class HistoryTabData extends Vue {
  @Prop({ type: Object })
  private order: Order

  private headers = []

  private historyLines = []

  private carOrCars = 'thisCar'

  private async created() {
    this.setHeaders()
    this.getHistoryLines()
  }

  private async getHistoryLines() {
    this.historyLines = json
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'Order',
        value: 'order',
      },
      {
        text: 'Product',
        value: 'product',
      },
      {
        text: 'Price',
        value: 'price',
      },
      {
        text: 'Supplier',
        value: 'supplier',
      },
      {
        text: 'In stock',
        value: 'inStock',
      },
    ]
  }
}
