import PositionSummary from '@/models/stock/PositionSummary'
import TyreHotelImage from '@/models/tyrehotel/TyreHotelImage'

export default class TyreHotelWheel {
  public id: number
  public tyreHotelWheelSetId: number
  public placement: string
  public tyreBrand: string
  public tyreModel: string
  public rimMaterialType: string
  public rimBrand: string
  public rimModel: string
  public dimension: string
  public loadIndex: string
  public speedIndex: string
  public hasBolts: boolean
  public isRunFlat: boolean
  public isCTyre: boolean
  public hasTyreDamages: boolean
  public hasRimDamages: boolean
  public comment: string
  public treadDepth: number
  public airPressure: number
  public isStudded: boolean
  public hasHubCaps: boolean
  public hasWheelLock: boolean
  public hasNitrogen: boolean
  public hasTpms: boolean
  public hasAirSuspension: boolean
  public tpmsType: string
  public tpmsBatteryLevel: number
  public dotMark: string
  public isSnowWear: boolean

  public image: TyreHotelImage = null
  public positionSummary: PositionSummary = null

  public static tyrePlacements: Array<string> = [
    'LeftFront',
    'RightFront',
    'LeftRear',
    'RightRear',
    'Extra1',
    'Extra2',
    'Extra3',
    'Extra4',
  ]

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.tyreHotelWheelSetId = data.tyreHotelWheelSetId ? (data.tyreHotelWheelSetId as number) : null
    this.placement = data.placement ? (data.placement as string) : null
    this.tyreBrand = data.tyreBrand ? (data.tyreBrand as string) : null
    this.tyreModel = data.tyreModel ? (data.tyreModel as string) : null
    this.rimMaterialType = data.rimMaterialType ? (data.rimMaterialType as string) : null
    this.rimBrand = data.rimBrand ? (data.rimBrand as string) : null
    this.rimModel = data.rimModel ? (data.rimModel as string) : null
    this.dimension = data.dimension ? (data.dimension as string) : null
    this.loadIndex = data.loadIndex ? (data.loadIndex as string) : null
    this.speedIndex = data.speedIndex ? (data.speedIndex as string) : null
    this.hasBolts = data.hasBolts ? (data.hasBolts as boolean) : null
    this.isRunFlat = data.isRunFlat ? (data.isRunFlat as boolean) : null
    this.isCTyre = data.isCTyre ? (data.isCTyre as boolean) : null
    this.hasTyreDamages = data.hasTyreDamages ? (data.hasTyreDamages as boolean) : null
    this.hasRimDamages = data.hasRimDamages ? (data.hasRimDamages as boolean) : null
    this.comment = data.comment ? (data.comment as string) : null
    this.treadDepth = data.treadDepth ? (data.treadDepth as number) : null
    this.airPressure = data.airPressure ? (data.airPressure as number) : null
    this.isStudded = data.isStudded ? (data.isStudded as boolean) : null
    this.hasHubCaps = data.hasHubCaps ? (data.hasHubCaps as boolean) : null
    this.hasWheelLock = data.hasWheelLock ? (data.hasWheelLock as boolean) : null
    this.hasNitrogen = data.hasNitrogen ? (data.hasNitrogen as boolean) : null
    this.hasTpms = data.hasTpms ? (data.hasTpms as boolean) : null
    this.hasAirSuspension = data.hasAirSuspension ? (data.hasAirSuspension as boolean) : null
    this.tpmsType = data.tpmsType ? (data.tpmsType as string) : null
    this.tpmsBatteryLevel = data.tpmsBatteryLevel ? (data.tpmsBatteryLevel as number) : null
    this.dotMark = data.dotMark ? (data.dotMark as string) : null
    this.isSnowWear = data.isSnowWear ? (data.isSnowWear as boolean) : null

    this.image = data.image ? new TyreHotelImage(data.image as Record<string, unknown>) : null
  }

  public clone(): TyreHotelWheel {
    const result = new TyreHotelWheel()
    Object.assign(result, this)
    return result
  }

  public getShortPlacementForTranslation(): string {
    const shortPlacement = this.placement ? this.placement.replace(/[^A-Z0-9]+/g, '') : '?'
    return 'c:tyre-hotel-placement-short:' + shortPlacement
  }

  public getPositionName(): string {
    return this.positionSummary?.positionName || ''
  }

  public getLocationAndPositionText(): string {
    if (!this.positionSummary?.positionName) {
      return ''
    }
    return this.positionSummary?.locationCode + '.' + this.positionSummary?.positionName
  }
}
