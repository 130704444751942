































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { axios } from '@/plugins/vueaxios'
import { vxm } from '@/store'
import Booking from '@/models/booking/Booking'

@Component({})
export default class CancelDialog extends Vue {
  $refs: Vue['$refs'] & {
    cancelOrderForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Boolean, required: false })
  private value: boolean

  @Prop({ type: String, required: false })
  private recipient: string

  @Prop({ type: Booking, required: false })
  private booking: Booking

  private visible = false
  private loading = false

  private cancelOrder = true
  private returnProductsToStock = true
  private cancelBooking = false
  private cancelBatchInvoicing = false
  private cancelCredit = false
  private emailCustomer = false

  private sendEmailData = {
    recipients: null,
  }

  // ================================================================
  // Manage dialog visibility
  // ================================================================

  // On created, start with the visibility given by the v-model prop
  public created(): void {
    if (this.value) {
      this.visible = true
    }
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value
    if (
      this.visible &&
      (!this.sendEmailData.recipients || this.sendEmailData.recipients.length === 0) &&
      this.recipient
    ) {
      this.sendEmailData.recipients = [this.recipient]
    }
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close() {
    if (!this.loading) {
      this.$emit('input', false)
    }
  }

  private send() {
    if (this.$refs.cancelOrderForm.validate()) {
      this.loading = true
      let success = false

      // axios
      //   .post('/v4/site/notification/send/email', this.sendEmailData)
      //   .then(() => {
      //     vxm.alert.success(this.$t('E-mail successfully sent'))
      //     this.$refs.sendEmailForm.reset()
      //     success = true
      //   })
      //   .catch((err) => {
      //     vxm.alert.onAxiosError(err, this.$t('Could not send email'))
      //   })
      //   .finally(() => {
      //     this.loading = false
      //     if (success) {
      //       this.close()
      //     }
      //   })
    }
  }

  protected validateRecipient(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
}
