










































































import { Component, Prop, Vue } from 'vue-property-decorator'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TyreHotelWheelChange from '@/models/tyrehotel/TyreHotelWheelChange'
import TyreHotelWheelSet from '@/models/tyrehotel/TyreHotelWheelSet'

@Component({
  components: {},
})
export default class TyreHotelData extends Vue {
  @Prop({ type: Object, required: true })
  private tyreHotel: TyreHotel

  @Prop({ type: Object, required: true })
  private tyreHotelWheelChange: TyreHotelWheelChange

  private receiveTyreHotelWheelSet: TyreHotelWheelSet = null
  private returnTyreHotelWheelSet: TyreHotelWheelSet = null

  private wheelSetPositionText = ''

  private created() {
    this.getTyreHotelWheelSetSeasonData()
  }

  private openTyreHotel() {
    this.$router.push({
      name: 'TyreHotel/View',
      params: { tyreHotelId: String(this.tyreHotel.id) },
    })
  }

  private getTyreHotelWheelSetSeasonData() {
    this.receiveTyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets.find(
      (tyreHotelWheelSet) => tyreHotelWheelSet.id === this.tyreHotelWheelChange.receiveTyreHotelWheelSetId,
    )

    this.returnTyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets.find(
      (tyreHotelWheelSet) => tyreHotelWheelSet.id === this.tyreHotelWheelChange.returnTyreHotelWheelSetId,
    )

    if (this.receiveTyreHotelWheelSet.isOnHotel) {
      this.$axios
        .get('/v4/site/tyre-hotels-v2/wheel-set/' + this.receiveTyreHotelWheelSet.id + '/stock-positions')
        .then((response) => {
          this.processStockPositions(response.data.data)
        })
    } else {
      this.$axios
        .get('/v4/site/tyre-hotels-v2/wheel-set/' + this.returnTyreHotelWheelSet.id + '/stock-positions')
        .then((response) => {
          this.processStockPositions(response.data.data)
        })
    }
  }

  private processStockPositions(positions) {
    if (!positions.length) {
      this.wheelSetPositionText = ''
      return
    }

    const grouped: { [key: string]: Set<string> } = {}

    positions.forEach((position) => {
      const location = position.locationName
      const positionName = position.positionName
      const key = location

      if (!grouped[key]) {
        grouped[key] = new Set()
      }
      grouped[key].add(positionName)
    })

    this.wheelSetPositionText = Object.entries(grouped)
      .map(([location, positions]) => `${location} ${[...positions].join(', ')}`)
      .join('; ')
  }

  private get tyreStatusClass() {
    switch (this.tyreHotelWheelChange.status) {
      case this.$t('c:tyre-hotel:Cancelled'):
        return 'status-cancelled'
      case this.$t('c:tyre-hotel:Terminated'):
        return 'status-terminated'
      case this.$t('c:tyre-hotel:Open'):
        return 'status-open'
      default:
        return ''
    }
  }
}
