







































import { Vue, Component, Prop } from 'vue-property-decorator'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import { formatDateTimeString } from '@/utils/dateUtils'

@Component({
  methods: { formatDateTimeString },
  components: { DocumentDialog },
})
export default class CommentData extends Vue {
  public $refs: Vue['$refs'] & {
    uploadNewDocumentForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Number, required: true })
  private orderId: number

  private addCommentDialog = false

  private comment = ''
  private comments = []

  created() {
    // this.$axios.get('v4/site/order/comments/' + this.orderId).then((response) => {
    //   this.comments = response.data.data
    // })
  }

  private addCommentSectionToggle() {
    this.addCommentDialog = !this.addCommentDialog
  }
}
